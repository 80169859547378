import React, { Fragment, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import AnimatedContent from '../AnimatedContent/AnimatedContent';

import './Message.css';

const Message = ({ content, role, timestamp, status, index, retrySendMessage, prevTimestamp, stream, onStreamingComplete, messagesEndRef, isScrolledToBottom }) => {
  useEffect(() => {
    if (isScrolledToBottom && messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isScrolledToBottom, messagesEndRef]);

  const typingIndicator = <div className="typing-cursor"></div>;

  return (
    <Fragment>
      <div key={`${role}-${index}`} className={`message ${role} ${status}`}>
        <div className="message-text">
          {status === 'typing' && typingIndicator}
          {stream && (
            <span className={`text-background ${role}`}>
              <AnimatedContent
                content={content}
                animationType="stream"
                onAnimationComplete={onStreamingComplete}
              />
            </span>
          )}
          {!stream && status !== 'typing' && (
            <span className={`text-background ${role}`}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </span>
          )}
          {status === 'failed' && (
            <button className="retry-button" onClick={() => retrySendMessage(index)}>
              Retry
            </button>
          )}
        </div>
      </div>
      {messagesEndRef && <div ref={messagesEndRef}></div>}
    </Fragment>
  );
};

export default Message;