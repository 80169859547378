import React, { useState, useContext, useRef } from 'react';
import { ConversationContext } from './contexts/ConversationContext';
import './ChatbotWidget.css';
import MessageList from './components/MessageList/MessageList';
import MessageInput from './components/MessageInput/MessageInput';
import Header from './components/Header/Header';
import useScrollAndLoad from './hooks/useScrollAndLoad';
import useMessageHandling from './hooks/useMessageHandling';
import useWidgetState from './hooks/useWidgetState';

const ChatbotWidget = () => {
  const {
    messages,
    sendMessage,
    retrySendMessage,
    isLoadingOlderMessages,
    loadMoreMessages,
    isLoading,
    updateMessage,
  } = useContext(ConversationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [messageListHeight, setMessageListHeight] = useState(72);
  const [newMessage, setNewMessage] = useState('');
  const [messageToStream, setMessageToStream] = useState(null);
  const [inputDisabled, setInputDisabled] = useState(false);
  const messageInputContainerRef = useRef(null);
  const [initialLoading, setInitialLoading] = useState(true);

  const {
    messagesListRef,
    isScrolledToBottom,
    scrollToBottom,
  } = useScrollAndLoad(
    messages,
    isLoadingOlderMessages,
    loadMoreMessages,
    isOpen
  );

  useMessageHandling(setIsOpen);
  useWidgetState(
    isOpen,
    initialLoading,
    setInitialLoading,
    messageListHeight,
    messagesListRef
  );

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const newMessageObj = {
        id: Date.now(),
        content: newMessage,
        timestamp: Math.floor(Date.now() / 1000),
        status: 'sending',
        role: 'user',
      };
      setInputDisabled(true);
      setMessageToStream(newMessageObj);
      setNewMessage('');
      await sendMessage(newMessage);
      scrollToBottom();
    }
  };

  const handleStreamingComplete = (index) => {
    updateMessage(index, { stream: false });
    setInputDisabled(false);
    scrollToBottom();
  };

  return (
    <div className="chatbot-widget-container">
      <div className={`chat-window ${isOpen ? 'open' : ''}`}>
        <Header isOpen={isOpen} toggleChat={() => setIsOpen(!isOpen)} />
        <MessageList
          messagesListRef={messagesListRef}
          isLoadingOlderMessages={isLoadingOlderMessages}
          isLoading={isLoading}
          messages={messages}
          retrySendMessage={retrySendMessage}
          handleStreamingComplete={handleStreamingComplete}
          isScrolledToBottom={isScrolledToBottom}
        />
        <div ref={messageInputContainerRef}>
          <MessageInput
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            setMessageListHeight={setMessageListHeight}
            messageListHeight={messageListHeight}
            handleSendMessage={handleSendMessage}
            inputDisabled={inputDisabled}
            isLoading={isLoading}
            isStreaming={messageToStream !== null}
            isOpen={isOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatbotWidget;
